<template>
  <div class="login-container box">
    <div class="warp">

      <div ref="imgRef" class="imgs">
        <el-carousel :height="carouselHeight" trigger="click">
          <el-carousel-item v-for="(item, index) in carouselArr" :key="index">
            <div class="carousel-img" :style="item" />
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="login">
        <div class="login-box">
          <div class="title">
            <p>安骏跨境物流大屏展示</p>
            <p>WELCOME</p>
          </div>
          <div class="inp_box">
            <div class="name">
              <!-- <p>账号</p> -->
              <div>
                <i class="el-icon-user" />
                <input v-model.trim="loginForm.username" min="3" max="20" placeholder="请输入账号" type="text" @keyup.enter.prevent="handleLogin">
              </div>
            </div>
            <div class="name pwd">
              <!-- <p>密码</p> -->
              <div>
                <i class="el-icon-lock" />
                <input v-model.trim="loginForm.password" type="password" min="3" max="20" placeholder="请输入密码" @keyup.enter.prevent="handleLogin">
              </div>
            </div>
          </div>
          <div class="but">
            <button type="button" @click="handleLogin">登录</button>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { strRSA } from '@/utils/RSA_util';
import { getUserIpInformation } from '@/api/login';
import png1 from '@/assets/image/loginImg/1.png';
import png2 from '@/assets/image/loginImg/2.png';
import png3 from '@/assets/image/loginImg/3.png';
import png4 from '@/assets/image/loginImg/7.png';
import png5 from '@/assets/image/loginImg/9.jpg';
import png6 from '@/assets/image/loginImg/11.png';
export default {
  name: 'Login',
  data() {
    return {
      loginForm: {
        username: '',
        password: ''
      },
      passwordType: 'password',
      redirect: undefined,

      carouselArr: [
        {
          backgroundImage: 'url(' + png1 + ')'
        },
        {
          backgroundImage: 'url(' + png2 + ')'
        },
        {
          backgroundImage: 'url(' + png3 + ')'
        },
        {
          backgroundImage: 'url(' + png4 + ')'
        },
        {
          backgroundImage: 'url(' + png5 + ')'
        },

        {
          backgroundImage: 'url(' + png6 + ')'
        }
      ],
      carouselHeight: '', // 轮播图高度
      lastHistory: '' // 跳转路径
    };
  },
  watch: {
    $route: {
      handler: function(route) {
        this.redirect = route.query && route.query.redirect;
      },
      immediate: true
    }
  },

  created() {
    this.lastHistory = sessionStorage.getItem('lastHistory');
    const goURLStr = this.goHTTPS();
    goURLStr && window.location.replace(goURLStr);
  },

  mounted() {
    // 绑定enter事件
    this.enterKeyup();
    this.carouselHeight = this.getImgsHeight(this.$refs.imgRef);
    window.addEventListener('resize', () => {
      this.carouselHeight = this.getImgsHeight(this.$refs.imgRef);
    });
  },

  destroyed() {
    // 销毁enter事件
    this.enterKeyupDestroyed();
  },
  methods: {
    goHTTPS() {
      const ENV = process.env.NODE_ENV;
      // console.log(ENV);
      const href = window.location.href;
      // 开发环境
      if (ENV === 'development') {
        return '';
      }

      // 正式环境
      const { protocol, pathname, host } = new URL(href);

      // 当前已经是https了
      if (protocol === 'https:') {
        return '';
      }
      return `https://${host}${pathname}`;
    },
    /**
     * 注册
     */
    enterKeyup() {
      document.addEventListener('keyup', this.enterKey);
    },

    /**
     * 销毁
     */
    enterKeyupDestroyed() {
      document.removeEventListener('keyup', this.enterKey);
    },

    /**
     * 在当前页面监听回车键
     */
    enterKey(event) {
      const componentName = this.$options.name;
      if (componentName !== 'Login') { return; }
      if (event.key !== 'Enter') { return; }
      this.handleLogin();
    },

    // 账户密码 简单检测
    checkInut() {
      const { username, password } = this.loginForm;
      if (!username.length) {
        this.$message({
          message: '请输入账号',
          type: 'warning'
        });
        return false;
      }
      if (!password.length) {
        this.$message({
          message: '请输入密码',
          type: 'warning'
        });
        return false;
      }
      return true;
    },

    handleLogin() {
      const isBool = this.checkInut();
      if (!isBool) { return; }

      getUserIpInformation(this.loginForm).then(res => {
        if (res.code === 200) {
          sessionStorage.setItem('token', strRSA(res.data.token));
          this.$router.replace({ path: this.lastHistory });
          window.location.replace('');
        }
      })
    },

    /**
     * 获取高度
     */
    getImgsHeight(doms) {
      return window.getComputedStyle(doms, null).height;
    }

  }
};
</script>

<style>
@supports (-webkit-mask: none) and (not (cater-color: #fff)) {
  .login-container .el-input input {
    color: #fff;
  }
}

/* reset element-ui css */
.login-container .el-input {
  display: inline-block;
  height: 30px;
  width: 85%;
}
.login-container input {
  background: transparent;
  border: 0;
  -webkit-appearance: none;
  border-radius: 0;
  padding: 0 10px;
  color: #fff;
  height: 30px;
}
.login-container .el-form-item {
  border: 1px solid rgba(255, 255, 255, 0.1);
  background: rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  color: #454545;
}
</style>

<style scoped>
.login-container {
  height: 100%;
  width: 100%;
  overflow: hidden;
}
.login-container .login-form {
  position: relative;
  width: 520px;
  max-width: 100%;
  padding: 160px 35px 0;
  margin: 0 auto;
  overflow: hidden;
}

.login-container .tips {
  font-size: 14px;
  color: #fff;
  margin-bottom: 10px;
}

.svg-container {
  padding: 6px 5px 6px 15px;
  color: #889aa4;
  vertical-align: middle;
  width: 30px;
  display: inline-block;
}

.title-container {
  position: relative;
}
.title-container .title {
  font-size: 26px;
  color: #eee;
  margin: 0 auto 40px auto;
  text-align: center;
  font-weight: bold;
}

.show-pwd {
  position: absolute;
  right: 10px;
  top: 7px;
  font-size: 16px;
  color: #889aa4;
  cursor: pointer;
  user-select: none;
}
p {
  margin: 0;
  padding: 0;
}

.box {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #D6E9FF;
}
.box .warp {
  width: 80%;
  height: 80%;
  display: flex;
  border-radius: 30px;
  overflow: hidden;
}

.imgs,
.login {
  height: 100%;
}
.imgs {
  width: 60%;
  border: 1px solid transparent;
}

.login {
  background: #fff;
  width: 40%;
  display: flex;
  justify-content: center;
}
.login-box {
  margin-top: 15%;
  width: 80%;
}
.title p {
  margin: 0;
  padding: 0;
  font-weight: bold;
  color: #007efd;
}
p:nth-child(1) {
  font-size: 50px;
}
p:nth-child(2) {
  margin-top: 5px;
  font-size: 40px;
}

.inp_box {
  margin-top: 80px;
}
.name div {
  display: flex;
  align-items: center;
  padding: 5px 10px;
  border: 1px solid #dcdfe6;
  border-radius: 20px;
}
.name i {
  width: 20px;
  height: 20px;
  margin-right: 5px;
  color: #C0C4CC;
}

input {
  width: 100%;
  border: none;
  background:none;
  outline:none;
  color: #606266;
}
input::-webkit-input-placeholder {
  display: flex;
  margin-top: -5px;
  color: #C0C4CC;
  font-size: 12px;
}

.pwd {
  margin-top: 20px;
}

.but {
  width: 100%;
  margin-top: 100px;
}
.but button {
  width: 100%;
  height: 40px;
  border-radius: 5px;
  border: none;
  background-color: rgb(0,126,253);
  color: #fff;
  outline:none;
  cursor: pointer;

}
button::after {
  border: none;
}

.carousel-img {
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
</style>
